import React, { useState, useRef, useEffect } from "react";
import { Card, Form, Input, Button, Select, Collapse, Divider,Checkbox, Row, Col, InputNumber, Space, Spin, message } from "antd";
import constants from '../../../constants/constants';
import { CaretRightOutlined } from "@ant-design/icons";
import { fetchAdminApi } from "../../../services/api";
import { COOKIE, getCookie } from "../../../services/cookie";
import { useHistory } from "react-router";
import TextArea from "antd/es/input/TextArea";

const { Panel } = Collapse;
const { Option } = Select;

const EditableCheatSheet = ({ customSelect, date, initialFormValue  }) => {
	const history = useHistory();
	const [form] = Form.useForm();
	const first_name = getCookie(COOKIE.LoginType) === "0" ? `${getCookie(COOKIE.FirstName)} ${getCookie(COOKIE.LastName)}`: "";
	const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0;
    };
	const initialValues = isEmptyObject(initialFormValue)===false ? initialFormValue : {
		first_name: first_name
	};
	const [selectedSubstances, setSelectedSubstances] = useState({});
	const [maritalStatus, setMaritalStatus] = useState(['single', 'divorced', 'married']);
	const [newMaritalStatus, setNewMaritalStatus] = useState('');
	const maritalStatusInputRef = useRef(null);
	const [gender, setGender] = useState(['Male', 'Female']);
	const [newGender, setNewGender] = useState('');
	const genderInputRef = useRef(null);
	const [ethnicity, setEthnicity] = useState(['Asian Indian', 'White', 'Black', 'Hispanic']);
	const [newEthnicity, setNewEthnicity] = useState('');
	const ethnicityInputRef = useRef(null);
	const [religion, setReligion] = useState(['Hindu', 'Muslim', 'Christian', 'Buddhist', 'Jewish','Sikhs']);
	const [newReligion, setNewReligion] = useState('');
	const religionInputRef = useRef(null);
	const [selectedReasons, setSelectedReasons] = useState({});
	const [loader, setLoader] = useState(false);

	// Handle checkbox change for reason
	const handleCheckboxReaChange = (reason, isChecked) => {
		setSelectedReasons((prev) => ({
			...prev,
			[reason]: { ...prev[reason], checked: isChecked, cause: isChecked ? prev[reason]?.cause : "", },
		}));
	};
	
	const addReligion = () => {
		if (!newReligion) return;
		setReligion([...religion, newReligion]);
		setNewReligion('');
		setTimeout(() => religionInputRef.current?.focus(), 0);
	};

	const addEthnicity = () => {
		if (!newEthnicity) return;
		setEthnicity([...ethnicity, newEthnicity]);
		setNewEthnicity('');
		setTimeout(() => ethnicityInputRef.current?.focus(), 0);
	};

	const addGender = () => {
		if (!newGender) return;
		setGender([...gender, newGender]);
		setNewGender('');
		setTimeout(() => genderInputRef.current?.focus(), 0);
	};

	const addMaritalStatus = () => {
		if (!newMaritalStatus) return;
		setMaritalStatus([...maritalStatus, newMaritalStatus]);
		setNewMaritalStatus('');
		setTimeout(() => maritalStatusInputRef.current?.focus(), 0);
	};

	const handleCheckboxSubChange = (substance, isChecked) => {
		setSelectedSubstances((prev) => ({
			...prev,
			[substance]: { ...prev[substance], checked: isChecked },
		}));
	};

	const handleAgeChange = (substance, age) => {
        setSelectedSubstances((prev) => ({
            ...prev,
            [substance]: { ...prev[substance], age },
        }));
    };

	const handleGamblingChange = (substance, value) => {
        setSelectedSubstances((prev) => (
		{
			...prev,
			[substance]: { ...prev[substance], textValue: value },
		}));
    };
	
	const handleSave = (value) => {
		setLoader(true);
		let userAnswerValue = value;
		if (selectedReasons) userAnswerValue.selectedreasons = selectedReasons;
		if (selectedSubstances) userAnswerValue.selectedsubstances = selectedSubstances;

		let payload = {
			section_id: "0",
			notes_type: "4",
			dt_created: date,
			answers: userAnswerValue,
			client_id: getCookie(COOKIE.ClientId),
			notes_content: "",
			notes_assesment: "",
			notes_plan: ""
		};
		fetchAdminApi('/add-notes', 'post', payload).then((res) => {
			if (res?.code && res.code === 200) {
				setLoader(false);
				form.resetFields();
				setSelectedReasons({});
				setSelectedSubstances({});
				history.push("/dashboard");
				message.success("Pre-Assessment Summary saved successfully");
			} else {
				message.error(res?.message);
				setLoader(false);
			}
		})
	}

	const drugsForm = () => {
		return (
			<div className='flex flex-col'>
				{(<Space direction="vertical" className="mb-3">
					{constants.Substances.map((substance) => (
						<Form.Item key={substance.key} className="mb-3">
							<div className='h-[36px] flex items-center'>
								<Checkbox
									onChange={(e) => handleCheckboxSubChange(substance.key, e.target.checked)}
									checked={selectedSubstances?.[substance.key]?.checked ? true : false}
								>
									<p className='text-[14px]'><b>{substance.substances}</b></p>
								</Checkbox>
								{selectedSubstances?.[substance.key]?.checked && (
									<>
										<b className='text-[14px] font-normal mr-2'>{"at age"}</b>
										<InputNumber
											value={selectedSubstances?.[substance.key]?.age ? selectedSubstances?.[substance.key]?.age : ""}
											placeholder="Enter age"
											min={1}
											style={{ width: 100 }}
											onChange={(value) => handleAgeChange(substance.key, value)}
										/>
									</>
								)}
							</div>
							{
								substance.key === "gambling" && selectedSubstances?.[substance.key]?.checked ?
									<TextArea
										value={selectedSubstances?.["gambling"]?.textValue}
										placeholder="Enter gambling..."
										onChange={(value) => handleGamblingChange("gambling", value.target.value)}
									/> :
									<p className="text-blue-500 text-[14px]">{substance.key !== "gambling" ? "-":""}{constants.SubstancesAliases[substance.key]}</p>
							}
						</Form.Item>
					))}
				</Space>)}
			</div>
		)
	};

	const panelTitle = (tittle) => {
		return <span className="text-base font-semibold">{tittle}</span>
	};

	useEffect(() => {
		setFormFields(initialValues);
	}, [initialValues]);

	const setFormFields = (value) => {
		form.setFieldsValue(value);
		if(value?.selectedreasons) setSelectedReasons(value?.selectedreasons);
		if(value?.selectedsubstances) setSelectedSubstances(value?.selectedsubstances);
	};

	const updatedReasons = constants.Reasons.map((reason) => ({
		...reason,
		reason: reason.reason.replace(/\slike$/, "") // Remove " like" at the end if it exists
	}));

	return (
		<Spin spinning={loader} size="large">
			<Card title={<span className="text-lg font-bold">{constants.PreAssessmentSummy}</span>} bordered style={{ maxWidth: 2000, margin: "10px auto" }}>
				<Form
					form={form}
					layout="vertical"
					onFinish={handleSave}
				>
					<Collapse
						defaultActiveKey={["1"]}
						className='pt-5'
						expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
						size="large" accordion
					>
						<Panel header={panelTitle("Presenting Problem")} key="1">
							<Form.Item
								name="patient_problem"
								className='mb-0'
								//will be using in future
								// rules={[
								// 	{
								// 		required: true,
								// 		message: "Please select at least one option!",
								// 		type: "array", // Important for Checkbox.Group validation
								// 	},
								// ]}
								>
								<Checkbox.Group>
									<Checkbox value={'3'}>Emotional</Checkbox>
									<Checkbox value={'2'}>Family</Checkbox>
									<Checkbox value={'1'}>Addict/Gambler</Checkbox>
								</Checkbox.Group>
							</Form.Item>
						</Panel>

						<Panel header={panelTitle("Basic Information")} key="2">
							<Row gutter={16}>
								<Col xs={24} lg={12}>
									<Form.Item label="Name" name="first_name">
										<Input placeholder="Enter name" />
									</Form.Item>
								</Col>
								<Col xs={24} lg={12}>
									<Form.Item label="Age" name="age">
										<Input placeholder="Enter age" />
									</Form.Item>
								</Col>
								<Col xs={24} lg={12}>
									<Form.Item label={constants.Gender} name="gender">
										{customSelect(
											true,
											"Male/Female",
											null,
											gender,
											addGender,
											newGender,
											(e) => handlePronounChange(e.target.value),
											genderInputRef
										)}
									</Form.Item>
								</Col>
								<Col xs={24} lg={12}>
									<Form.Item name={"intro_1"} label={constants.Status}>
										{
											customSelect(
												true,
												"Single/Divorced/Married",
												null,
												maritalStatus,
												addMaritalStatus,
												newMaritalStatus,
												(e) => setNewMaritalStatus(e.target.value),
												maritalStatusInputRef
											)
										}
									</Form.Item>
								</Col>
								<Col xs={24} lg={12}>
									<Form.Item label={constants.Ethniticity} name="intro_2">
										{customSelect(
											true,
											"Asian Indian/White/Black/Hispanic",
											null,
											ethnicity,
											addEthnicity,
											newEthnicity,
											(e) => setNewEthnicity(e.target.value),
											ethnicityInputRef
										)}
									</Form.Item>
								</Col>
								<Col xs={24} lg={12}>
									<Form.Item label={constants.Religion} name="intro_3">
										{customSelect(
											true,
											"Hindu/Muslim/Christian/Buddhist/Jewish/Sikhs",
											null,
											religion,
											addReligion,
											newReligion,
											(e) => setNewReligion(e.target.value),
											religionInputRef
										)}
									</Form.Item>
								</Col>
								<Col xs={24} lg={12}>
									<Form.Item label="Referred By" name="source">
										<Input placeholder="Enter referrer" />
									</Form.Item>
								</Col>
							</Row>
						</Panel>

						<Panel header={<div className="flex flex-col">
							{panelTitle("Substances/Gambling")}
							<span className="text-sm font-medium">Please check/tick whatever substance you have used and enter the age that you have started using.</span>
						</div>} key="3">
							{drugsForm()}
						</Panel>
						<Panel header={
							<div className="flex flex-col">
							{panelTitle("DSM Criteria")}
							<span className="text-sm font-medium">Has the substance use affected any of the following:</span>
							</div>} key="4">
							<Row gutter={[12, 12]}>
								{updatedReasons.map((reason) => (
									<Col xs={24} lg={12} key={reason.key}>
										<Checkbox
											onChange={(e) => handleCheckboxReaChange(reason.key, e.target.checked)}
											checked={selectedReasons?.[reason.key]?.checked ? true : false}
										>
											<p className="text-[14px]">{reason.reason}</p>
										</Checkbox>
									</Col>
								))}
							</Row>
						</Panel>

							<span className="text-lg font-bold text-gray-900"></span>
						<Panel header={
							<div className="flex flex-col">
								{panelTitle("Mental/Emotional Health")}
								<span className="text-sm font-medium">Have you ever been diagnosed with the following:</span>
							</div>
						} key="5">
							<Row gutter={[12, 12]}>
								{constants.MentalHealth.map((health) => (
									<Col xs={24} lg={12} key={health.key}>
										<Checkbox
											onChange={(e) => handleCheckboxReaChange(health.key, e.target.checked)}
											checked={selectedReasons?.[health.key]?.checked ? true : false}
										>
											<p className="text-[14px]">{health.mental_health}</p>
										</Checkbox>
									</Col>
								))}
							</Row>
						</Panel>

						<Panel header={panelTitle("Medical History")} key="6">
							<Form.Item label="Health Status" name={"medical_2"}>
								<Select placeholder="good/poor" allowClear>
									<Option value="good">good</Option>
									<Option value="poor">poor</Option>
								</Select>
							</Form.Item>
							<Form.Item label="Allergies" name="medical_4">
								<Select placeholder="Allergies/no Allergies" allowClear>
									<Option value="Allergies">Allergies</Option>
									<Option value="no Allergies">no Allergies</Option>
								</Select>
							</Form.Item>
							<Form.Item label="Medications" name="medical_8">
								<Select placeholder="no/medications/OTCs" allowClear>
									<Option value="medications/OTCs">medications/OTCs</Option>
									<Option value="no medications/OTCs">no medications/OTCs</Option>
								</Select>
							</Form.Item>
						</Panel>

						<Panel header={panelTitle("Family & Lifestyle")} key="7">
							<Form.Item label="Family Relations" name="family_4">
								<Select placeholder="fair/good/poor" allowClear>
									<Option value="fair">fair</Option>
									<Option value="good">good</Option>
									<Option value="poor">poor</Option>
								</Select>
							</Form.Item>
							<Form.Item label="Exercise" name="exercise">
								<Select placeholder="walks/gym/sports/none">
									<Option value="Walks">walks</Option>
									<Option value="Gym">gym</Option>
									<Option value="Sports">sports</Option>
									<Option value="None">none</Option>
								</Select>
							</Form.Item>
							<Form.Item label="Occupation" name="occupation">
								<Input placeholder="Enter occupation" />
							</Form.Item>
						</Panel>
					</Collapse>

					<Divider />
					<Form.Item>
						<Space className="float-right">
							<Button className='bg-sky-800 text-white uppercase' type='primary' htmlType="submit">
								{constants.Save}
							</Button>
						</Space>
					</Form.Item>
				</Form>
			</Card>
		</Spin>
	);
};

export default EditableCheatSheet;

