import { Row, Col, Image, Form, Input, Button, InputNumber, Select, Progress, Card, Table, Space, Modal, Upload, Tooltip, Radio, Popconfirm, Checkbox, Spin, Divider } from 'antd';
import constants from '../../constants/constants';
// import { SaveOutlined, UploadOutlined, CloseOutlined, InfoCircleOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import { DeleteOutlined, SaveOutlined, CheckOutlined, CheckCircleTwoTone, EditOutlined, UserOutlined, InfoCircleOutlined, TrophyOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import SignaturePad from 'react-signature-canvas';
import GaugeChart from 'react-gauge-chart';
import { COOKIE, getCookie } from '../../services/cookie';
import ReactHlsPlayer from 'react-hls-player';

const loginType = getCookie(COOKIE.LoginType);
const { TextArea } = Input;

const chartStyle = {
	height: 250,
	width: 540,
};

//templete to add titles.
export const template0 = (title, option_message, scale_msg) => {
	let value = null;
	if (title) {
		value = title;
	} else if (option_message) {
		value = option_message;
	} else if (scale_msg) {
		value = scale_msg;
	}
	return (
		<div>
			<div className='font-bold mb-3 text-center' dangerouslySetInnerHTML={{ __html: value }} />
		</div>
	);
};

//templete to add Images.
export const template1 = (src, type) => {
	if (type === "I") {
		return (
			<div className="grid place-content-center place-items-center py-3.5">
				<Image
					alt="img"
					preview={false}
					src={src}
				/>
			</div>
		);
	} else {
		return (
			<div className="grid place-content-center place-items-center py-3.5">
				<Image
					className="max-w-5xl"
					src={src}
					preview={{ src }}
				/>
			</div>
		);
	}
};

//templete to add data.
export const template2 = (title, data, option_message, scale_msg) => {
	if (option_message) {
		return <div className="font-semibold" dangerouslySetInnerHTML={{ __html: option_message }} />;
	} else if (scale_msg) {
		return <div className="font-semibold" dangerouslySetInnerHTML={{ __html: scale_msg }} />;
	}
	else {
		return (
			<div>
				<div className='font-semibold mb-3 text-center' dangerouslySetInnerHTML={{ __html: title }} />
				<div dangerouslySetInnerHTML={{ __html: data }} />
			</div>
		);
	}
};

//template for textarea with label (Admin- Checkbox)
export const template3 = (question, type_left, onChange, formName, tempData, isDisable, tooltipValue, placeholder, section_res, onCheckboxChange, isChecked) => {
	const rowValue = type_left === "TA" ? 8 : 1;
	return (
		<div className='py-2 labelInput'>
			<Tooltip title={tooltipValue} placement="bottom" trigger={['focus']}>
				<Form.Item
					name={formName}
					className="mb-0"
					label={
						question ? (
							<div className='flex flex-col'>
								{(section_res && loginType !== "0") ? (
									<Checkbox
										defaultChecked={isChecked}
										onChange={e => onCheckboxChange(e, tempData)}
									>
										<div
											dangerouslySetInnerHTML={{ __html: question }}
											className='text-start whitespace-normal self-end mb-2'
										/>
									</Checkbox>
								) : (
									<div
										dangerouslySetInnerHTML={{ __html: question }}
										className='text-start whitespace-normal self-end mb-2'
									/>
								)}
							</div>
						) : null
					}
				>
					<TextArea
						onChange={(e) => onChange(e.target.value, tempData, "text")}
						className={`w-full ${isDisable ? "bg-gray-200" : ""}`}
						autoSize={type_left !== "TA"}
						rows={rowValue}
						readOnly={isDisable}
						status={tempData?.is_edited === 1 ? "error" : null}
						placeholder={placeholder}
					/>
				</Form.Item>
			</Tooltip>
		</div>
	);
};

//template to display normal image and to preview image
export const template4 = (src, contentright, type) => {
	return (
		<div>
			<Row gutter={[24, 16]} className="py-3.5">
				<Col xs={24} lg={8} className="text-center">
					{type === "I" ? <Image
						className='border-2 rounded-md border-gray-300 bg-white overflow-hidden mt-1.5'
						alt="img"
						preview={false}
						src={src}
					/> : <Image
						width={400}
						height={300}
						className='mt-1.5'
						src={src}
						preview={{ src: src }}
					/>}
				</Col>
				<Col xs={24} lg={16}>
					<div dangerouslySetInnerHTML={{ __html: contentright }} />
				</Col>
			</Row>
		</div>
	);
};

//template with chaeckbox and textarea
export const template5 = (label, data, disable, onChangeCheckboxValue, onchangeText) => {
	return (
		<Col span={24}>
			<Form.Item
				name="template5">
				<Row gutter={[8, 8]}>
					<Col xs={24} lg={6}>
						<Checkbox
							disabled={disable}
							checked={data.check_box === 'f' ? false : true}
							onChange={e => onChangeCheckboxValue(e, data, data?.template_id)}>
							{<div dangerouslySetInnerHTML={{ __html: label }} />}
						</Checkbox>
					</Col>
					<Col xs={24} lg={18}>
						<TextArea
							status={data?.is_edited ? "error" : null}
							readOnly={disable}
							autoSize={true}
							rows={1}
							value={data.client_ans}
							disabled={data.check_box === "t" ? false : true}
							onChange={e => onchangeText(e, data, data?.template_id)} />
					</Col>
				</Row>
			</Form.Item>
		</Col>
	);
};

//template to display video link with image.
export const template6 = (contentLeft, title, url) => {
	return (
		<div className='text-center py-2'>
			{contentLeft !== "" && contentLeft !== null && (
				<a href={contentLeft} target="_blank" rel="noreferrer">
					<Image
						alt="Video thumbnail"
						preview={false}
						src={url}
					/>
				</a>
			)}
			<div>
				<Button type="link" className='text-lg font-semibold' href={contentLeft} target="_blank">{title}</Button>
			</div>
		</div>
	);
};
//template for radio group
export const template7 = (contentLeft, data, disable, onChange) => {
	let divclass = 'w-1/5 text-center p-3 border-black border-x-2 border-t-2';
	if (data?.is_edited) {
		divclass = 'w-1/5 text-center p-3 border-black border-x-2 border-t-2 flex';
	}
	return (
		<div className='flex table-bordered'>
			<div className='w-4/5 p-3 border-black border-l-2 border-t-2 font-semibold'>{contentLeft}</div>
			<div className={divclass}>
				{data?.is_edited ? <div className="mr-4 mt-1"><EditOutlined /></div> : null}
				<Form.Item
					name={data?.item_id}
					className="mb-0"
					rules={[
						{
							required: true,
							message: constants.RequiredField,
						},
					]}
				>
					<Radio.Group
						disabled={disable}
						onChange={(e) => onChange(e.target.value, data, "radio")}
					>
						<Radio id="yes" value={"1"}>Yes</Radio>
						<Radio id="no" value={"2"}>No</Radio>
					</Radio.Group>
				</Form.Item>
			</div>
		</div>
	);
};

//template to required form with select dropdown
export const template8 = (contentLeft, data, disable, onchangeSelect) => {
	let cssClass = null;
	if (data?.is_edited) {
		cssClass = 'w-full disabled-outline';
	} else {
		cssClass = 'w-full';
	}
	return (
		<div className='flex table-bordered w-6/12 mx-auto'>
			<div className='w-3/5 p-3 border-black border-l-2 border-t-2'>{contentLeft}</div>
			<div className='w-2/5 p-3 border-black border-x-2 border-t-2'>
				<Form.Item
					name={data?.item_id}
					className="mb-0"
					rules={[
						{
							required: true,
							message: constants.RequiredField,
						},
					]}
				>
					<Select
						status={data?.is_edited ? "error" : null}
						disabled={disable}
						showSearch
						onChange={(e) => onchangeSelect(e, data, "radio")}
						className={`disabled-select-dark ${cssClass}`}
						optionFilterProp="children"
						filterOption={(input, option) => {
							return option.label.toLowerCase().indexOf(input.toLowerCase()) > -1 && option.label.toLowerCase().indexOf(input.toLowerCase()) < 1;
						}}
						options={[
							{
								value: "1",
								label: 'Never',
							},
							{
								value: "2",
								label: 'Once in a great while',
							},
							{
								value: "3",
								label: 'Rarely',
							},
							{
								value: "4",
								label: 'Sometimes',
							},
							{
								value: "5",
								label: 'Often',
							},
							{
								value: "6",
								label: 'Usually',
							},
							{
								value: "7",
								label: 'Always',
							},
						]}
					/>
				</Form.Item>
			</div>
		</div>
	);
};

//template to display GaugeChart.
export const template9 = (persent) => {
	let val = persent / 7;
	return (
		<div className="text-center">
			<div className='flex justify-center items-center stress-chart'>
				<GaugeChart
					id="gauge-chart2"
					style={chartStyle}
					nrOfLevels={7}
					percent={val}
					formatTextValue={_value => persent}
				/>
			</div>
		</div>
	);
};

//template to display score in progress component.
export const template10 = (content_left) => {
	return (
		<div className="text-center">
			<div className="font-semibold mb-3">What is your score?</div>
			<Progress type="circle" percent={content_left} />
		</div>
	);
};

//template to display two images on left side and right side.
export const template11 = (contentleft, contentright, typeleft, typeright) => {
	return (
		<Row gutter={[8, 8]} className="py-3.5">
			<Col xs={24} lg={12} className="grid place-content-center place-items-center">
				{typeleft === "I" ? <Image
					className='border-2 rounded-md border-gray-300 overflow-hidden'
					alt="img"
					src={contentleft}
					preview={false}
				/> : <Image
					className='border-2 rounded-md border-gray-300 overflow-hidden'
					src={contentleft}
					preview={{ src: contentleft }}
				/>}
			</Col>
			<Col xs={24} lg={12} className="grid place-content-center place-items-center">
				{typeright === "I" ? <Image
					className='border-2 rounded-md border-gray-300 overflow-hidden'
					alt="img"
					src={contentright}
					preview={false}
				/> : <Image
					className='border-2 rounded-md border-gray-300 overflow-hidden'
					src={contentright}
					preview={{ src: contentright }}
				/>}
			</Col>
		</Row>
	);
};

// Label with input & textarea field inside a Card.
export const template12 = (content_left) => {
	return (
		<div className='grid place-content-center place-items-center'>
			<Card className='border-2 border-black p-3 my-4 w-9/12'>
				<div dangerouslySetInnerHTML={{ __html: content_left }} />
			</Card>
		</div>
	);
};

export const template13 = (content_left, data, disable, onChangeCheckboxValue) => {
	return (
		<div className='py-2'>
			{data?.is_edited ? <div className="mr-4"><CheckCircleTwoTone /></div> : null}
			<Form.Item
				name={data?.item_id}
				valuePropName="checked"
				className="mb-0"
			>
				<Checkbox
					disabled={disable}
					onChange={e => onChangeCheckboxValue(e.target.checked === true ? "1" : "0", data, "radio")}
				>{<div dangerouslySetInnerHTML={{ __html: content_left }} />}</Checkbox>
			</Form.Item>
		</div>
	);
};

//table code
export const template15 = (values, disable, tableOneColumns, tableComponents, tableOneDataSource, handleChange, handleAdd, handleActionSave, handleFutureChangeAndMyreaction, handleDelete, futureOptions, inputRef, newOption, handleNewOptionChange, handleAddNewOption, handleAddOnSpecificIndex, handleFearChange, handleCheckboxChange, handleFearInputChange) => {
	const column = tableOneColumns.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				tableName: values.content_left === "My List Table" ? "Mylist" : "Mylist2",
				handleAdd,
				disable: disable,
			}),
		};
	});
	const tableTwoColumns = [
		// section 4 table starts here
		{
			title: 'Name',
			dataIndex: 'name',
			width: '10%',
			editable: true,
			hidden: values?.content_left === "Anger Sheet table 2" || values?.content_left === "Anger Sheet table 3",
			render: (record, values) => {
				if (values?.isDuplicate) {
					return null
				} else {
					return <span>{record}</span>

				}
			}
		},
		{
			title: "What they did to me?",
			dataIndex: "problem",
			editable: true,
			width: "20%",
			hidden: values?.content_left === "Anger Sheet table 2" || values?.content_left === "Anger Sheet table 3" ,
		},
		// section 5 table starts here
		{
			title:"Who Or what do I fear?",
			dataIndex: "name",
			editable: true,
			width: "20%",
			hidden: values?.content_left === "Anger Sheet table",
			render: (record, values) => {
				if (values?.isDuplicate) {
					return null
				} else {
					return <span>{record}</span>

				}
			}
		},
		{
			title: "Why do I have this fear?",
			dataIndex: "fearCause",
			width: "15%",
			hidden: values?.content_left === "Anger Sheet table",
			editable: true
		},
		// common column
		{
			title: "Which Instinct was affected?",
			dataIndex: "affected",
			width: values?.content_left === "Anger Sheet table 2" ?"15%": "20%",
			hidden: values?.content_left === "Anger Sheet table 3",
			render: (_, record) => {
				return <Select
					disabled={disable}
					defaultValue={record.affected ? record.affected : []}
					onChange={(e) => handleChange(e, record)}
					mode="multiple"
					allowClear
					className="w-full"
					options={[
						{
							label: "Sex",
							options: [
								{
									label: "Connections",
									value: "Connections"
								},
								{
									label: "Relationships",
									value: "Relationships"
								}
							]
						},
						{
							label: "Social",
							options: [
								{
									label: "Fame",
									value: "Fame"
								},
								{
									label: "Popularity",
									value: "Popularity"
								},
								{
									label: "Self-esteem",
									value: "Self-esteem"
								}
							]
						},
						{
							label: "Survival",
							options: [
								{
									label: "Jobs",
									value: "Jobs"
								},
								{
									label: "Money",
									value: "Money"
								},
								{
									label: "Power",
									value: "Power"
								}
							]
						}
					]}
				/>;
			}
		},
		// section 4
		{
			title: "How did I react?",
			dataIndex: "reaction",
			editable: true,
			width: "20%",
			hidden: values?.content_left === "Anger Sheet table 2" || values?.content_left === "Anger Sheet table 3",
		},
		// section 4 table ends here
		// section 5
		{
			title:"How did I respond to fear?",
			dataIndex: "",
			width: "15%",
			hidden: values?.content_left === "Anger Sheet table",
			render: (_, record) => {
				const str = record.fearAction;
				const array = str ? str?.split(", ") : null ;
				return (
					<Select
						disabled={disable}
						defaultValue={array || null}
						onChange={(e) => handleFearChange(e, record)}
						mode="multiple"
						className="w-full"
						options={constants.ReactionResponses.map((label) => ({
							label: label,
							value: label,
						}))}
					/>
				);;
			},
		},
		// section 5 table ends here
		// common column
		{
			title: "What was my reaction?",
			dataIndex: "myreaction",
			hidden: values?.content_left === "Anger Sheet table 2" ||values?.content_left === "Anger Sheet table 3"  ? false: true,
			width: "22%",
			render: (_, record) => {
				let fearAction = record?.fearAction;
				let filterArray = [];
				if(fearAction){
					filterArray = fearAction?.split(", ");
				}
				return <Select
					disabled={disable}
					defaultValue={record.myreaction ? record.myreaction : []}
					onChange={(e) => handleFutureChangeAndMyreaction(e, record, "myreaction")}
					mode="multiple"
					className="w-full"
					options={
						filterArray.length > 0 ?
							filterArray.map((label) => ({
								label: label,
								options: constants.ReactionSigns[label]?.map((option) => ({
									label: option,
									value: option,
								})) || [],
							}))
							:
							constants.ReactionResponses.map((label) => ({
								label: label,
								options: constants.ReactionSigns[label].map((option) => ({
									label: option,
									value: option,
								})) || [],
							}))
					}
				/>
			}
		},
		{
			title: "How can I respond better?",
			dataIndex: "futureAction",
			width: 250,
			hidden: values?.content_left === "Anger Sheet table 3"  ? true: false,
			render: (_, record) => {
				return <Select
					disabled={disable}
					defaultValue={record.futureAction ? record.futureAction : []}
					onChange={(e) => handleFutureChangeAndMyreaction(e, record,"futureAction")}
					mode="tags"
					className="w-full"
					dropdownRender={(menu) => (
						<>
							{menu}
							<Divider style={{ margin: '8px 0' }} />
							<Space style={{ padding: '0 8px 4px' }}>
								<Input
									placeholder="Please enter option."
									ref={inputRef}
									value={newOption}
									onChange={handleNewOptionChange}
									onKeyDown={(e) => e.stopPropagation()}
								/>
								<Button type="text" icon={<PlusOutlined />} onClick={() => handleAddNewOption(values?.content_left === "Anger Sheet table")}>
									Add
								</Button>
							</Space>
						</>
					)}
					options={futureOptions
						.slice()
						.sort((a, b) => a.localeCompare(b))
						.map((item) => ({
							label: item,
							value: item,
						}))}
				/>
			}
		},
		{
			title: 'Action',
			dataIndex: 'action',
			align: "center",
			width: 150,
			ellipsis: true,
			hidden: values?.content_left === "Anger Sheet table 3",
			render: (_, record, index) => {
				return <Space>
					<Tooltip title={constants.Add}>
						<Button type="link" disabled={disable} onClick={() => handleAddOnSpecificIndex(index + 1, values.content_left === "Anger Sheet table" ? "AngerSheetTable" : values?.content_left === "Anger Sheet table 2" ? "Anger Sheet table 2" : "FearTable")} icon={<PlusOutlined />} /></Tooltip>
					<Tooltip title={constants.Save}>
						<Button type="link" disabled={disable} onClick={() => { handleActionSave(record, values); }} icon={<SaveOutlined />} /></Tooltip>
					{tableOneDataSource.length > 1 && (
						<Tooltip title={constants.Delete}>
							<Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
								<Button disabled={ record?.is_ticked && values?.content_left === "Anger Sheet table 2"  ? true : disable} type="link" danger icon={<DeleteOutlined />} />
							</Popconfirm></Tooltip >
					)}
				</Space>;
			}
		},
	].filter(item => !item.hidden);
	const tableTwoColumnsNoDropdown = [
		// section 6 table column starts here
		{
			title: 'My Flaws',
			dataIndex: 'flaws',
			render: (record, values) => {
				if (values?.isDuplicate) {
					return null
				} else {
					return <span>{record}</span>

				}
			}
		},
		{
			title: 'People or Institution we have hurt by our actions arising out of character flaws',
			dataIndex: 'name',
			editable: true,
		},
		{
			title: "What did we do or say to them?",
			dataIndex: "problem",
			editable: true,
		},
		{
			title: "How can we make this right?",
			dataIndex: "affected",
			render: (_, record) => (
				<Select
					mode="tags"
					disabled={disable}
					defaultValue={record.affected ? record.affected : []}
					onChange={(e) => handleChange(e, record)}
					className="w-full"
					options={constants.Sec6Options.slice().sort((a, b) => a.localeCompare(b)).map(item => ({
						label: item,
						value: item
					}))}
				/>
			),
		},
		{
			title: 'Action',
			dataIndex: 'action',
			align: "center",
			render: (_, record, index) => {
				return <Space>
					<Tooltip title={constants.Add}>
						<Button type="link" disabled={disable} onClick={() => handleAddOnSpecificIndex(index + 1, "tableCode")} icon={<PlusOutlined />} /></Tooltip>
					<Tooltip title={constants.Save}>
						<Button type="link" disabled={disable} onClick={() => { handleActionSave(record); }} icon={<SaveOutlined />} /></Tooltip>
					{tableOneDataSource.length > 1 && (
						<Tooltip title={constants.Delete}>
							<Popconfirm disabled={disable} title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
								<Button type="link" danger icon={<DeleteOutlined />} />
							</Popconfirm></Tooltip >
					)}
				</Space>;
			}
		},
	];
	const column2 = tableTwoColumns.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record) => ({
				record,
				editable: (col.title === "Who Or what do I fear?" || col.title === 'Name') && record?.is_ticked||
				(col.title === "Who Or what do I fear?" || col.title === 'Name') && record?.isDuplicate ||
					(record?.isChecked !== 1 && values?.content_left === "Anger Sheet table 3") 
					?
					false :
					(col.title === "Who Or what do I fear?" || col.title === 'Name') && record?.is_add ? true:
					col.editable,
				dataIndex: col.dataIndex,
				tableName: values.content_left === "Anger Sheet table 3" ? "AngerSheetTable3" : values.content_left === "Anger Sheet table" ? "AngerSheetTable" : "FearTable",
				disable: disable,
				handleAdd
			}),
		};
	});
	const column3 = tableTwoColumnsNoDropdown.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record) => ({
				record,
				editable: col.title === "My Flaws" && record?.isDuplicate ? false : col.editable,
				dataIndex: col.dataIndex,
				tableName: "tableCode",
				disable: disable,
				handleAdd
			}),
		};
	});
	return (
		<div>
			{
				values.content_left === "Anger Sheet table" || values.content_left === "Anger Sheet table 2" || values.content_left === "table code" || values.content_left === "Anger Sheet table 3" ?
					<>
						<Table
							scroll={{ x: 900, y: 600}}
							components={tableComponents}
							rowClassName={() => 'editable-row'}
							bordered
							dataSource={/* values?.client_ans !== null ? values?.client_ans : */ tableOneDataSource}
							columns={values.content_left === "table code" ? column3 : column2}
							pagination={false}
							title={() => values?.is_edited ? <CheckCircleTwoTone /> : null}
						/>
						{((values.content_left === "Anger Sheet table" && values.section_id === "4") || (values.content_left === "Anger Sheet table 2" && values.section_id === "5")) && loginType === "0" ? (
							<Button className='bg-sky-800 text-white uppercase text-xs font-semibold mt-2 mb-6'
							disabled={disable}	
							onClick={() => handleAdd(
									"ADD",
									values.content_left === "Anger Sheet table"
										? {
											key: tableOneDataSource.length + 1,
											name: "",
											problem: "",
											affected: "",
											reaction: "",
											futureAction: "",
											table: "AngerSheetTable",
											is_pushed: 0,
											item_id: null
										}
										: {
											key: tableOneDataSource.length + 1,
											name: "",
											fearAction: "",
											fearCause: "",
											affected: "",
											futureAction: "",
											table: "FearTable",
											is_add: true,
											is_pushed: 0,
											item_id: null
										},
									values.content_left === "Anger Sheet table" ? "AngerSheetTable" : "FearTable",
									"ADD ROW"
								)}
							>
								{constants.AddRow}
							</Button>
						) : null}
					</>
					:
					<div className="w-2/5 mx-auto">
						<Table
							components={tableComponents}
							rowClassName={() => 'editable-row'}
							bordered
							dataSource={/* values?.client_ans !== null ? values?.client_ans : */ tableOneDataSource}
							columns={column}
							pagination={false}
							title={() => values?.is_edited ? <CheckCircleTwoTone /> : null}
						/>
					</div>
			}
		</div>
	);
};

// Table with header & sub header having checkboxes in each column.
export const template18 = (values, disable, onChangeCheckboxTable, checkedValue) => {
	const column = [
		{
			title: 'My List',
			dataIndex: 'mylist',
			width: '50%',
		},
	];
	let datasource = [];
	if (values.content_left === "table checkbox value" || values.content_left === "Anger Sheet table 3") {
		if (values.client_ans) {
			if (values.client_ans.length > 0) {
				datasource.length = 0;
				for (let i = 0; i < values.client_ans.length; i++) {
					datasource.push({ mylist: values.client_ans[i] });
				}
			}
		}
	}
	//To show or hide Spiritual column.
	const includedOptions = values?.client_ans ? values?.client_ans?.filter(answer =>
		constants.SpiritualOptions?.some(option => option.value === answer)
	) : [];

	const tableHeader1 = [
		[
			{
				title: 'General',
				isVisible: true,
			},
			{
				title: 'Spiritual',
				isVisible: includedOptions?.length > 0 ? true : false,
			},
			{
				title: 'Buddhism',
				isVisible: true,
			},
			{
				title: 'Christianity',
				isVisible: true,
			},
			{
				title: 'Hinduism',
				isVisible: true,
			},
			{
				title: 'Sikhism',
				isVisible: true,
			},
			{
				title: 'Islam',
				isVisible: true,
			}
		],
		[
			{
				title: 'The greatest defects of humans are',
				isVisible: true,
			},
			{
				title: 'Social Evils',
				isVisible: includedOptions?.length > 0 ? true: false,
			},
			{
				title: 'Obscurations',
				isVisible: true,
			},
			{
				title: 'Sins',
				isVisible: true,
			},
			{
				title: 'Skhandas',
				isVisible: true,
			},
			{
				title: 'Enemies of Man',
				isVisible: true,
			},
			{
				title: 'Nafs',
				isVisible: true,
			},
		]
	];

	const tableHeader2 = [
		[
			{
				title: 'Physical Vulnerability',
				isVisible: true,
			},
			{
				title: 'Economic Vulnerability',
				isVisible: true,
			},
			{
				title: 'Social Vulnerability',
				isVisible: true,
			},
			{
				title: 'Emotional and Pyschological Vulnerability',
				isVisible: true,
			},
			{
				title: 'Other',
				isVisible: true,
			}
		]
	];

	const tableCheckBoxData1 = [
		{
			list: constants.GeneralOptions,
			class: 'flex items-center',
			isVisible: true,
		},
		{
			list: constants.SpiritualOptions,
			class: 'flex items-center',
			isVisible: includedOptions?.length > 0 ? true: false,
		},
		{
			list: constants.BuddhismOptions,
			class: null,
			isVisible: true,
		},
		{
			list: constants.ChristianityOptions,
			class: null,
			isVisible: true,
		},
		{
			list: constants.HinduismOptions,
			class: null,
			isVisible: true,
		},
		{
			list: constants.SikhismOptions,
			class: null,
			isVisible: true,
		},
		{
			list: constants.IslamOptions,
			class: null,
			isVisible: true,
		},
	];

	const tableCheckBoxData2 = [
		{
			list: constants.Type1,
			class: null,
			isVisible: true,
		},
		{
			list: constants.Type2,
			class: null,
			isVisible: true,
		},
		{
			list: constants.Type3,
			class: null,
			isVisible: true,
		},
		{
			list: constants.Type4,
			class: null,
			isVisible: true,
		},
		{
			list: constants.Type5,
			class: null,
			isVisible: true,
		}
	];

	const getCheckBoxRowData = (checkBoxArray,className=null) => {
		return <td>
			<Space direction="vertical">
				{checkBoxArray
				?.slice()
				?.sort((a, b) => a.label.localeCompare(b.label))
				?.map((option) => {
					return <Tooltip title={option.tooltip} color='#075985'><div className={className}>
						<Checkbox key={option.value} value={option.value}>{option.label}</Checkbox>
					</div></Tooltip>
				})}
			</Space>
		</td>
	};

	const getTableHeader = () => {
		const header = values.content_left === "table with checkboxes code?" ? tableHeader1 : tableHeader2;
		return header.map((row, rowIndex) => (
		  <tr key={rowIndex}>
			{row.map((col, colIndex) => 
			  col.isVisible ? <th key={colIndex}>{col.title}</th> : null
			)}
		  </tr>
		));
	};

	const getTableRowData = () => {
		const rowData = values.content_left === "table with checkboxes code?" ? tableCheckBoxData1 : tableCheckBoxData2;
		return rowData.map((data) => (
			data.isVisible ? getCheckBoxRowData(data.list, data.list): null
		));
	};

	return (
		<>
			{values.content_left === "table with checkboxes code?" || values.content_left === "Anger Sheet table 3" ?
				<div className='overflow-x-auto'>
					<Checkbox.Group disabled={disable} onChange={onChangeCheckboxTable} value={checkedValue}>
						<table className="w-full blue-header">
							{values?.is_edited ? <caption className="text-left"><CheckCircleTwoTone /></caption> : null}
							<thead>{getTableHeader()}</thead>
							<tbody>
								<tr>{getTableRowData()}</tr>
							</tbody>
						</table>
					</Checkbox.Group>
				</div> :
				<div className="w-2/5 mx-auto">
					<Table
						columns={column}
						dataSource={datasource}
						pagination={true}
					></Table>
				</div>}
		</>
	);
};

// Checkbox with input field below.
export const template19 = (content_left, data, disable, onChangeCheckboxValue, onchangeText) => {
	return (
		<div>
			<div>
				<Checkbox
					disabled={disable}
					checked={data.check_box === 'f' ? false : true}
					onChange={e => onChangeCheckboxValue(e, data, data?.template_id)}
				>
					<div dangerouslySetInnerHTML={{ __html: content_left }} />
				</Checkbox>
			</div>
			<div className='pl-6 mb-5'>
				<TextArea
					status={data?.is_edited ? "error" : null}
					readOnly={disable}
					autoSize={true}
					rows={1}
					value={data.client_ans}
					disabled={data.check_box === "t" ? false : true}
					onChange={e => onchangeText(e, data, data?.template_id)}
				/>
			</div>
		</div>
	);
};
//signature pad template.
export const template20 = (sigPad, clear, handleEnd) => {
	return (
		<Card title={<h3>Signature</h3>}>
			<div className="sigContainer">
				<SignaturePad canvasProps={{ className: 'sigPad' }}
					ref={sigPad}
					onEnd={handleEnd}
				/>
				<Tooltip title="Clear">
					<Button type="text" danger className="absolute bottom-0 right-0 m-4" onClick={clear} icon={<DeleteOutlined />} />
				</Tooltip>
			</div>
		</Card>
	);
};

//lusher color test
export const luscherColorTest = (boxes, handleBoxClick, item_id, testTaken, clientName, resetLusherColor, downloadResult) => {
	let spanValue = "";
	if (loginType === "0") {
		spanValue = "You have already taken the Color Personality Test.";
	} else {
		spanValue = `${clientName} has already taken the Color Personality Test.`;
	}
	return (
		<>
			<Row
				gutter={[48, 48]}
				className="border-2 border-solid border-black p-8"
			>
				{testTaken !== null ?
					<>
						<Space>
							<span className="font-bold">{spanValue}</span>
							{loginType === "1" && <div><Button className='bg-sky-800 text-white' onClick={resetLusherColor}>Reset</Button></div>}
							{loginType === "1" && <div><Button className='bg-sky-800 text-white' onClick={downloadResult}>Download</Button></div>}
						</Space>
					</>
					: boxes.map((box) => (
						<Col xs={24} md={12} lg={6} key={box.id}>
							{loginType === "1" ?
								<div
									className="h-36 w-40 rounded-md cursor-pointer m-auto"
									style={{
										backgroundColor: box.color,
										display: box.visible ? "block" : "none"
									}}
								>
								</div> :
								<div
									className="h-36 w-40 rounded-md cursor-pointer m-auto"
									style={{
										backgroundColor: box.color,
										display: box.visible ? "block" : "none"
									}}
									onClick={() => handleBoxClick(box.id, box.color, item_id)}
								>
								</div>
							}
						</Col>
					))}
			</Row>
		</>
	);
};

//checkbox grid
export const template24 = (content_left, data, disable, onChangeCheckboxValue) => {
	const className = data?.type_left === 'CB2' ? 'py-2 w-1/2 md:w-1/4 inline-block' : 'py-2 mr-5 inline-block';
	return (
		<div className={className}>
			{data?.is_edited ? <div className="mr-4"><CheckCircleTwoTone /></div> : null}
			<Form.Item
				name={data?.item_id}
				valuePropName="checked"
				className="mb-0"
			>
				<Checkbox
					disabled={disable}
					onChange={e => onChangeCheckboxValue(e.target.checked === true ? "1" : "0", data, "radio")}
				>
					<div dangerouslySetInnerHTML={{ __html: content_left }} />
				</Checkbox>
			</Form.Item>
		</div>
	);
};

//checkbox with image
export const template26 = (content_left, data, disable, onChangeCheckboxValue) => {
	return (
		<div className='py-2 inline-block w-1/4 checkbox-start'>
			<Form.Item
				name={data?.item_id}
				valuePropName="checked"
				className="mb-0"
			>
				<Checkbox
					disabled={disable}
					onChange={e => onChangeCheckboxValue(e.target.checked === true ? "1" : "0", data, "radio")}
				>
					<Image
						alt="img"
						className='!w-60'
						preview={false}
						src={content_left}
					/>
				</Checkbox>
			</Form.Item>
		</div>
	);
};

// YouTube video
export const templateYouTube = (contentLeft) => {
	return (
		<div className="flex justify-center my-5">
			<iframe width="560" height="315" src={contentLeft} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
		</div>
	)
};

// Video streaming template
export const templateVideoStreaming = (videoUrl) => {
	return (
		<div className='flex justify-center'>
			<ReactHlsPlayer
				src={videoUrl}
				autoPlay={false}
				controls={true}
				width="50%"
				height="auto"
				hlsConfig={{
					maxLoadingDelay: 4,
					minAutoBitrate: 0,
					lowLatencyMode: true,
				}}
			/>
		</div>
	)
};

//template with checkbox and textarea for Admin (Admin- Checkbox)
export const template29 = (label, data, disable, onCheckboxChange, onchangeText) => {
	return (
		<Col span={24}>
			<Form.Item
				name="template29">
				<Row gutter={[8, 8]}>
					<Col xs={24} lg={6}>
						{loginType !== "0" ? <Checkbox
							defaultChecked={data.dt_checked ? true : false}
							onChange={(e) => { onCheckboxChange(e, data); }}>
							{<div dangerouslySetInnerHTML={{ __html: label }} />}
						</Checkbox>
							: < div className='font-bold text-base' dangerouslySetInnerHTML={{ __html: label }} />
						}
					</Col>
					<Col xs={24} lg={18}>
						<TextArea
							className={`w-full ${disable ? "bg-gray-200" : ""}`}
							status={data?.is_edited ? "error" : null}
							readOnly={disable}
							autoSize={true}
							rows={1}
							value={data.client_ans}
							onChange={e => onchangeText(e, data, data?.template_id)} />
					</Col>
				</Row>
			</Form.Item>
		</Col>
	);
};

// Template with checkbox and conditionally displayed text (no textbox) (Admin- Checkbox)
export const template30 = (label, data, disable, onCheckboxChange) => {
	return (
		<Col span={24}>
			<Form.Item name="template30">
				{disable ? (
					<Checkbox
						defaultChecked={!!data.dt_checked}
						onChange={(e) => onCheckboxChange(e, data)}
					>
						<div dangerouslySetInnerHTML={{ __html: label }} />
					</Checkbox>
				) : (
					<div className="font-bold text-base" dangerouslySetInnerHTML={{ __html: label }} />
				)}
			</Form.Item>
		</Col>
	);
};
